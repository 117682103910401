/*-----------------------------------------------------------------------------------
    Template Name: Ayan Theme - Multipurpose Ecommerce HTML Template
    Template URI: http://html.ayantheme.com
    Author: Ayan CO
    Version: 1.0

    Note: This is functions js File. all function imported in jquery function list.
-----------------------------------------------------------------------------------*/

(function ($) {
  //zooming image on mouse over

  $.fn.imageZoom = function (options) {
    let element = this;

    var defaults = {
      //lens width,lens an area of the image that is zoomed
      lensWidth: 0,
      //lens height,lens an area of the image that is zoomed
      lensHeight: 0,
      //zoomed image box width
      zoomBoxWidth: 0,
      //zoomed image box heghit
      zoomBoxHeight: 0,
      //Magnification rate
      zoomSize: 4,
      //fullscreen container css class for fullscreen mode
      fullscreenContainer: "",
      //when clicked on this area, image change to fullscreen mode
      fullscreenCssClass: "fullscreen",
      //Zoomed imaage box display delay time
      delay: 0,
      //Zoomed imaage box mousemove speed
      speed: 300,
    };

    var settings = $.extend({}, defaults, options);
    $(element).css({ position: "relative" });
    $(element).addClass("image-zoom");

    $(element).on("mouseenter", function (e) {
      const offset = $(element).closest(".product-gallery").offset();

      if ($(element).closest("." + settings.fullscreenCssClass).length > 0)
        return;

      let lens = $('<div class="zoom-lens"></div>');
      settings.lensWidth = $(element).find("img").width() / settings.zoomSize;
      settings.lensHeight = $(element).find("img").height() / settings.zoomSize;
      lens.css({
        height: settings.lensHeight + "px",
        width: +settings.lensWidth + "px",
      });

      $(element).append(lens);

      let zoomBox = $('<div class="zoom-box"></div>');
      zoomBox.hide();
      let img = $(element).find("img").clone();
      img.attr("class", "");
      zoomBox.append(img);
      $("body").append(zoomBox);
      zoomBox.fadeIn("slow");

      let zoomBoxImageWidth =
        $(element).find("img").width() * settings.zoomSize;
      let zoomBoxImageHeight =
        $(element).find("img").height() * settings.zoomSize;
      img.css({ width: zoomBoxImageWidth, height: zoomBoxImageHeight });

      settings.zoomBoxWidth = $(element).width();
      settings.zoomBoxHeight = $(element).height();

      zoomBox.css({
        width: settings.zoomBoxWidth,
        height: settings.zoomBoxHeight,
        top: offset.top,
        left: offset.left + $(element).width() + 50,
      });
    });
    $(element).on("mousemove", function (e) {
      // if($(element).closest('.'+settings.fullscreenCssClass).length>0)
      //     return;

      var offset = $(this).offset();

      var relX = e.pageX - offset.left - settings.lensWidth / 2;
      var relY = e.pageY - offset.top - settings.lensHeight / 2;

      $(element).find(".zoom-lens").css({ left: relX, top: relY });

      let rateX = $(".zoom-box img").width() / 2 - settings.zoomBoxWidth / 2;
      let rateY = $(".zoom-box img").height() / 2 - settings.zoomBoxHeight / 2;

      $(".zoom-box img").css({
        transform: `translate3d(${rateX - relX * settings.zoomSize}px,${
          rateY - relY * settings.zoomSize
        }px, 0px) scale(1,1)`,
      });
    });

    $(element).on("mouseleave", function (e) {
      // if($(element).closest('.'+settings.fullscreenCssClass).length>0)
      //     return;

      $(element).find(".zoom-lens").remove();
      $(".zoom-box").remove();
    });
    if (settings.fullscreenContainer) {
      $(element).on("click", function () {
        if ($(element).closest("." + settings.fullscreenCssClass).length > 0)
          return;
        $(element).find(".zoom-lens").remove();
        $(".zoom-box").remove();
        zoomFullScreenEffect($(settings.fullscreenContainer)[0], settings);
      });
    }
  };

  //fullscreen mode
  function zoomFullScreenEffect(element, options) {
    let box = element;

    let pos = $(box).offset();
    let width = $(box).width();
    let height = $(box).height();

    $(box).css({
      position: "absolute",
      left: pos.left + "px",
      top: pos.top + "px",
      width: width,
      height: height,
    });

    $(box).addClass(options.fullscreenCssClass);

    setTimeout(() => {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        100
      );

      $(box).animate(
        {
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
        },
        options.speed,
        function () {
          $(box).css({
            position: "fixed",
          });
        }
      );
    }, options.delay);

    $(box)
      .find(".btn-close")
      .off("click")
      .on("click", function () {
        $(box).css({
          position: "absolute",
        });
        $(box).animate(
          {
            left: pos.left + "px",
            top: pos.top + "px",
            width: width,
            height: height,
          },
          options.speed,
          function () {
            $(box).css({
              position: "",
              left: "",
              top: "",
              width: "",
              height: "",
            });

            $(box).removeClass(options.fullscreenCssClass);
          }
        );
      });
  }

  //set effect to display the text
  $.fn.textEffect = function (options) {
    //typing effect
    if (options.type === "typing") {
      var defaults = {
        //display delay time
        delay: 0,
        //display speed time
        speed: 100,
        //display effect type
        type: "typing",
        //selectors for elements that have '.letter' css class
        letterClass: "letter",
      };

      var settings = $.extend({}, defaults, options);

      typingEffect(this, settings);
    }
  };

  //typing effect
  function typingEffect(element, options) {
    let text = element.text().trim();
    element.html("");
    setTimeout(() => {
      element.html('<span class="letters"></span>');
      var i = 0;
      var timer = setInterval(function () {
        if (i < text.length) {
          element
            .find(".letters")
            .append(
              `<span ${
                options.letterClass ? `class="${options.letterClass}"` : ``
              }>${text.charAt(i)}</span>`
            );
          i++;
        } else {
          clearInterval(timer);
        }
      }, options.speed);
    }, options.delay);
  }


//make title of content from contant page
  $.fn.makeToc = function (options) {
    const defaults = {
      //result container css that result import there.
      resultContainer: "",
      //css class to select titles for linked them
      sectionsTitleSelector: "",
    };

    const elements = this;

    const settings = $.extend({}, defaults, options);
    const html = $('<ul class="toc" />');

    elements.each(function () {
      const link = $("<a />");
      link.attr("class", "nav-link").attr("href", "#" + $(this).attr("id"));
      link.text($(this).find(settings.sectionsTitleSelector).text().trim());
      html.append($("<li />").append(link));
    });
    $(settings.resultContainer).append(html);

    $(settings.resultContainer)
      .find("li a")
      .on("click", function (e) {
        let el = this;
        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: $($(el).attr("href")).offset().top - 150,
            },
            1000
          );
          e.preventDefault();
      });

    const mainNavLinks = $(settings.resultContainer).find("li a");

    $(window).on("scroll", function () {
      const fromTop = window.scrollY + 80;
      mainNavLinks.each(function () {
        const el = $(this);
        const section = $(el.attr("href"));

        if (
          section.offset().top - 150 <= fromTop &&
          section.offset().top - 150 + section.outerHeight() > fromTop
        ) {
          el.addClass("active");
        } else {
          el.removeClass("active");
        }
      });
    });
  };

  //make countdown timer to reach the target date
  $.fn.countdown = function (options) {
    const defaults = {
      //target date
      date: "Jan 5, 2024 15:37:25",
    };

    const element = this;

    const settings = $.extend({}, defaults, options);
    const countDownDate = new Date(settings.date).getTime();

    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if ($(element).find(".seconds .value").length > 0) {
        $(element).find(".days .value").text(days.toString().padStart(2, "0"));
        $(element)
          .find(".hours .value")
          .text(hours.toString().padStart(2, "0"));
        $(element)
          .find(".minutes .value")
          .text(minutes.toString().padStart(2, "0"));
        $(element)
          .find(".seconds .value")
          .text(seconds.toString().padStart(2, "0"));
      } else {
        $(element).text(
          `${(days * 24 + hours).toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
        );
      }

      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x);
        $(element).text("EXPIRED");
      }
    }, 1000);
  };
})(jQuery);
